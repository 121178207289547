.banner{
    background-image: url("../../Assets/Drinkapp/Banner.png");
    background-size: 20rem 10rem;
    width: 20rem;
    height: 10rem;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: absolute;
    left: 10%;
    top: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

.banner h3:hover{
    cursor:pointer;
}

.banner h3{
    color: lightskyblue;
}
.banner a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

.banner a:hover{
    background: rgba(0,0,0,0.3);
}

.banner a.active{
    background: var(--color-primary);
    color: var(--color-bg);
}