#contained-streetbites body{
    margin:0px;
    padding: 0px;
    
    background-color: #ffffff;
}
*{
    box-sizing: border-box;
}
#contained-streetbites ul{
    list-style: none;
}
#contained-streetbites a{
    text-decoration: none;
}
#contained-streetbites button{
    outline: none;
    border: none;
}
#contained-streetbites input{
    outline: none;
    border: none;
}
.logo img{
    width: 150px;
}
#main-streetbites{

    width:100%;
    height:1080px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../Assets/StreetBite/StreetBite\ Background.png');
}
#contained-streetbites nav{
	display: flex;
	justify-content: space-around;
	align-items: center;
	left: 0;
	top: 0;
	width:100%;
	z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
}
#contained-streetbites nav.active{
	box-shadow: 5px 10px 30px rgba(0, 247, 255, 0.157);
	background-color: #ffffff;
}
#contained-streetbites nav ul{
	display: flex;
}
#contained-streetbites .active{
    background-color: #ff5e00;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(255, 176, 50, 0.336);
	transition: all ease 0.2s;
}
#contained-streetbites nav ul li a{
	height:40px;
	line-height: 43px;
	margin: 3px;
	padding: 0px 22px;
	display: flex;
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: 500;
	color:#000000;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}
#contained-streetbites nav ul li a:hover{
	background-color: #ff9d00;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(255, 176, 50, 0.336);
	transition: all ease 0.2s;
}
#contained-streetbites nav .menu-btn,
.menu-icon{
		display:none;
	}
.name-streetbites{
	width: 500px;
	position: absolute;
    left: 22%;
	top: 50%;
	transform: translate(-22%,-50%);
}
.name-streetbites span{
    color: #ff5e00;
}
.name-streetbites .details{
    color: #969696;
}
.name-streetbites h1{
	font-size: 40px;
	margin:0px;
	letter-spacing: 2px;
	color:#000000;
}
.header-btns{
    display: flex;
    margin-top: 40px;
}
.cv-btn-streetbites{
	width:150px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    background-color: #ff5e00;
    box-shadow: 5px 10px 30px rgba(255, 176, 50, 0.336);
    border-radius: 10px;
	color:#ffffff;
}
.cv-btn-streetbites:hover{
	background-color: #ff9d00;
    transition: all ease 0.5s;
    color: #ffffff;
}

.f-heading h1{
    color:#FFFFFF;
    font-size: 3rem;
    margin: 0px;
    padding: 0px;

}
.f-heading p{
    color:rgba(247, 247, 247, 0.678);
    font-size: 1rem;
    margin: 0px;
    padding: 0px;
}
.f-heading{
    text-align: center;
    margin-top: 60px;
    margin-bottom: -80px;
}

.blog-streetbites{
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 100px;
    color: black;
}
.blog-content-streebites{
    background-color: rgba(255, 198, 173, 0.496);
}
.blog-content-streebites li{
    top: 0;
    margin-top: 150px;
    position: sticky;
    text-align: center;
}
.blog-content-streebites h2{
    margin: 20px;
}
.mainfeatures-streetbites{
    margin-top: 100px;
    margin-bottom: 100px;
    margin-right: 20%;
    margin-left: 20%;
}
.mainfeatures-streetbites h1{
    text-align: left;
}
.mainfeatures-streetbites p{
    font-size: 18px;
    margin:10px;
}
.mainfeatures-streetbites h4{
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    
    margin:10px;
}
.mainfeatures-streetbites img{
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 30px;
    height: 200px;
}

.mainfeatures-streetbites-gallery{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 15px;
}
.mainfeatures-streetbites-gallery img{
    margin-left: 20%;
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

#contained-streetbites  #about{
	width:100%;
	background-color:#F2F5F5;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.about-text-streetbites{
    width: 45%;
    color: #fff;
    list-style: circle;
}
.about-text-streetbites h2{
	font-size: 2rem;
	color:#ff5e00;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.about-text-streetbites p{
    width: 80%;
	font-size: 1.2rem;
	color:#272727;
	
}
.about-text-streetbites button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
	border: none;
    outline: none;
    color: #ffffff;
	background-color:#ff5e00;
}
.about-text-streetbites button:hover{
	background-color: rgb(255, 187, 50);
    transition: all ease 0.3s;
    color: #ffffff;
}
.about-image{
    width: 50%;
}
.about-image img{
  width: 600px;
}

#contained-streetbites #features{
    width:100%;
    height:100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
    background-color: #ffffff;
}
.a-container{
    display: flex;
    justify-content: center;
    align-content: center;


}
.a-box{
    background-color: #ffffff;
    width:300px;
    height: 450px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 10px 12px rgba(0,0,0,0.1);
    transition: 0.3s ease-in-out;
    position: relative;
}
.a-b-img{
    width: 100%;
    height: 60%;

}
.a-b-img img{
    padding: 15px;
    width:100%;
    height: 100%;

}
.a-box:hover{
    box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
}
#presentaion{
    padding-top: 30px;
    width:100%;
    height:1080px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../Assets/Drinkapp/presentation.png);
    text-align: center;
}
#presentaion h1{
font-size: 2rem;
}
#contained-streetbites #services{
    width:100%;
    height:100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
}

.s-heading h1{
    color:#FFFFFF;
    font-size: 3rem;
    margin: 0px;
    padding: 0px;

}
.s-heading p{
    color:rgba(247, 247, 247, 0.678);
    font-size: 1rem;
    margin: 0px;
    padding: 0px;
}
.s-heading{
    text-align: center;
    margin: 20px 0px;
}
.s-box{
    background-color: #ffffff;
    width:350px;
    height: 470px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
    position: relative;
}
.b-container{
    display: flex;
    justify-content: center;
    align-content: center;


}
.s-b-img{
    width: 100%;
    height: 50%;

}
.s-b-img img{
    width:100%;
    height: 100%;

}
.s-b-text{
    width: 100%;
    height: 40%;
    background-color:#ffffff;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.s-b-text p{
    margin: 0px;
    color:#000266a6;
    font-size: 1.1rem;
    display: block;
    display: -webkit-box;
    max-width: 80%;
    margin-top: 25px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.s-b-text p:hover{
    color:#000266;
}
.s-b-text a{
    margin-top:15px ;
}
.s-type{
    color:#000266;
    background-color: #ffffff;
    padding: 10px 15px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
}


#contained-streetbites #contact{
    background-color: #00b7ff;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    padding: 30px 0px;
}
#contained-streetbites #contact h3{
	font-size:2.5rem;
    margin:20px;
    color: #fff;
}
.contact-input{
	width:400px;
	height:50px;
	background-color:#FFFFFF;
	display:flex;
	justify-content: center;
	border-radius: 50px;
	box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
}
.contact-input input{
	width:100%;
	background-color: transparent;
	border:none;
	outline: none;
	text-align: center;
	color:#242425;
}
.contact-input a{
	width:200px;
	height:35px;
	background-color:#00b7ff;
	color:#FFFFFF;
	display: flex;
	font-size: 0.9rem;
	justify-content: center;
	align-items: center;
	margin: auto 10px;
	border-radius: 20px;
    font-weight: 500;
    transition: 0.4s ease-out;
}
.contact-input a:hover{
    background-color:#0099ff;
}
@media(max-width:1190px){
	#main{
		background-size: 1150px !important;
		
	}
	.name{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
	}
	.about-image img{
		height:400px;
    }
    #contained-streetbites #features{
		height:auto;
    }
    .a-container{
		flex-wrap: wrap;
	}
    .a-box{
		flex-grow: 1;
	}
    #contained-streetbites #services{
		height:auto;
    }
    .b-container{
		flex-wrap: wrap;
	}
    .s-box{
		flex-grow: 1;
	}
	.s-b-img img{
		object-fit:cover;
    }
    .a-b-img img{
		object-fit:contain;
    }
    
    

}

@media(max-width:970px){
	.main{
		background-image: none !important;
		
	}
	.name{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	.about-image{
        display:none;
    }
    .cv-btn{
        margin-left: 27%;
        margin-top: 20px;
    }
	#contained-streetbites #about{
		justify-content: center;
        padding-top: 0px;
        text-align: center;
        
        margin-top: 0px;
	}
	.about-text{
        width: 90%;
	}
	.about-text h1{
		font-size:4rem;
    }
    .about-text p{
        width: 100%;
    }
    #contained-streetbites #features{
        margin-bottom: 100px;
    }
	#contained-streetbites #presentaion{
        display: none;
    }
}
@media(max-width:600px){
	.name{
		width:60%;
    }
    .mainfeatures-streetbites {
        margin-top: 100px;
        margin-bottom: 100px;
        margin-right: 2%;
        margin-left: 2%;
    }
    .about-image img{
        width: 50px;
      }
    .mainfeatures-streetbites-gallery {
        display: grid
    ;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(100px, auto);
        grid-gap: 15px;
    }
    .scroll{
        left: 42.5%;
    }
    .blog-streetbites {
        display:block;
    }
    .name-streetbites {
        width: auto;
        position: absolute;
        left: 22%;
        top: 50%;
        transform: translate(-22%, -50%);
    }
}
@media(max-width:1100px){
    .menu-icon{
    display:block;
}
        #contained-streetbites nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .logo img{
            width:70px;
        }
        .header-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color:#FFFFFF;
            border-bottom:4px solid #1db096;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        #contained-streetbites nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(38,38,38,0.03);
        }
        #contained-streetbites nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            user-select: none;
        }
        #contained-streetbites nav .menu-icon .nav-icon{
            background-color:#333333;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:18px;
        }
        #contained-streetbites nav .menu-icon .nav-icon:before,
        #contained-streetbites nav .menu-icon .nav-icon:after{
            background:#333;
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        #contained-streetbites nav .menu-icon .nav-icon:before{
            top:5px;
        }
        #contained-streetbites nav .menu-icon .nav-icon:after{
            top:-5px;
        }
        #contained-streetbites nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        #contained-streetbites nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        #contained-streetbites nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        #contained-streetbites nav .menu-btn{
            display:none;
        }
        #contained-streetbites nav .menu-btn:checked ~ .menu{
            display:block;
        }
}
@media(max-width:400px){
            .s-box{
                width: 100%;
                height:400px;
            }
}
