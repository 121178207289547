.gallery-body{
    margin: 10% 10%;
}

.item{
    min-width: 30rem;
    min-width: 20rem;
    padding: 40px;
}

.item img{
    width: 100%;
    height: 100%;
    border-radius:2rem;
    pointer-events: none;
}

.inner-carousel{
    display: flex;
}

.carousel{
    cursor: grab;
    overflow: hidden;
}

.uititle{
    text-align: center;
}

.webgallery-body{
}

.webitem{
    width: 100px;
    height: 35rem;
    padding: 40px;
}
.webitem img{
    width: 100%;
    height: 100%;
    border-radius:1rem;
    pointer-events: none;
}