:root{
    --width: 84%;
    --mobile-width:92;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}

.body__navbar{
    color: black;
    background: white;
}

.container__navbar{
    width: var(--width);
    margin: 0 auto;
}

.img__navbar{
    width: 100%;
}

.nav__navbar a{
    color: var(--color-black);
    transition: all 300ms ease;
}

.nav__navbar h1,.nav__navbar h2,.nav__navbar h3,.nav__navbar h4,.nav__navbar h5{
    color: var(--color-black);
    font-weight: 800;
}

.nav__navbar h1{
    font-size: 6rem;
    letter-spacing: -0.4rem;
    line-height: 0.8;
}

.nav__navbar h2{
    font-size: 3.2rem;
    letter-spacing: -0.2rem;
}

.nav__navbar h3{
    font-size: 2rem;
}

.nav__navbar h4,.nav__navbar h5{
    font-size: 1.4rem;
    font-weight: 500;
}

.h5__navbar{
    font-size: 0.9rem;
}

.p__navbar{
    line-height: 1.6;
}

.nav__navbar .btn{
    padding: 1rem 2rem;
    border-radius: 3rem;
    font-weight: 500;
    background: var(--color-black);
    color: var(--color-white);
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 300ms ease;
}

.nav__navbar .btn:hover{
    background: var(--color-white);
    color: var(--color-black);
    border: 1px solid var(--color-black);
}

.nav__navbar .btn-primary{
    background: var(--color-primary);
    color: var(--color-white);
}

.nav__navbar section{
    max-width: 100vw;
    padding: 12rem 0 0;
} 

/* =============================== NAVBAR ====================================== */
.window-scroll{
    background: var(--color-primary);
}

.nav__navbar{
    width: 100vw;
    height: 6rem;
    display: grid;
    place-items: center;
    position: fixed;
    z-index: 2;
    background: white;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}

.nav__navbar button{
    display: none;
}

.nav__navbar .container__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__navbar .container__navbar ul{
    display: flex;
    gap: 3rem;
}

.nav__navbar .container__navbar ul a{
    padding: 0 0 0.3rem;
}

.nav__navbar .container__navbar ul a.active{
    border-bottom: 6px solid var(--color-primary-variant);
}

.nav__navbar a:hover{
    color: var(--color-primary-variant)
}

.nav__navbar .icons{
    display: flex;
    gap:2rem;
    font-size: 1.2rem;
}

.nav__navbar .logo{
    width: 12%;
}

/* =============================== PRODUCTS ====================================== */

header .container__navbar{
    display: grid;
    grid-template-columns: 30rem auto 17rem;
    align-items: center;
    max-height: 100%;
    margin-top: 4rem;
}

header .container__navbar .info .delivery{
    background: var(--color-primary-variant);
    width: max-content;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    color: var(--color-black);
    margin-bottom: 1.5rem;
}

header .container__navbar .info .delivery i{
    display: inline-block;
    transform: rotateY(180deg);
    margin-left: 1rem;
}

header .container__navbar .info p{
    font-size: 1.3rem;
    font-weight: 500;
    margin: 1rem 0 2rem;
}

header .container__navbar .info .cta{
    display: flex;
    align-items: center;
}

header .container__navbar .info .cta img{
    width: 3.6rem;
    transition: all 300ms ease;
    filter: grayscale(100%)
}

header .container__navbar .info .cta a:last-child{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 1rem;
    font-weight: 500;
}

header .container__navbar .info .cta a:last-child:hover{
    color: var(--color-black);
}

header .container__navbar .info .cta a:last-child:hover img{
    filter: saturate(0);
}

/* =============================== COMMERCIALS ====================================== */

section#commercial{
    background: var(--color-primary-variant);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0;
    display: grid;
    place-items: center;
    display: none;
}

section#commercial .container__navbar{
    width: 74%;
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.5);
}

section#commercial .container__navbar video{
    width: 100%;
}

/* =============================== PRODUCTS ====================================== */

section#products .container__navbar{
    width: 56%;
}

section#products .container__navbar > h1,
section#products .container__navbar > p {
    text-align: center;
}

section#products article{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top: 5rem;
}

section#products article p{
    margin: 1.5rem 0 1rem;
}

section#products article:last-child{
    grid-template-columns: 60% 40%;
}

section#products article:last-child .image{
    margin-right: -8rem;
}

section#products a:hover{
    text-decoration: underline;
}

.info h6{
    color: #7e7e7e;
}

/* =============================== WHY CHOOSE US ====================================== */

section#why-choose-us .container__navbar .head{
    display: flex;
    justify-content: space-between;
}

section#why-choose-us .container__navbar .head .left h4 {
    font-style: italic;
    color: var(--color-black);
}

section#why-choose-us .container__navbar .head .right{
    display: flex;
    gap: 2rem;
    width: 16rem;
}

section#why-choose-us .container__navbar .head .right .image{
    width: 8rem;
}

section#why-choose-us .container__navbar .head .right h4{
    margin-top: 1rem;
    color: var(--color-black);
    font-weight: 700;
}

section#why-choose-us .container__navbar article{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 3rem;
    margin-top: 6rem;
}

section#why-choose-us .container__navbar article h4{
    margin-bottom: 1.4rem;
    color: var(--color-black);
    font-weight: 700;
}

section#why-choose-us .container__navbar article p{
    margin-bottom: 1rem;
}

section#why-choose-us .container__navbar article a{
    display: inline-block;
    margin-top: 1rem;
}

section#why-choose-us .container__navbar article a img{
    width: 8rem;
    transition: all 300ms ease;
}

section#why-choose-us .container__navbar article a:hover img{
    filter: saturate(0);
    transition: all 300ms ease;
}

/* =============================== FAQS ====================================== */

section#faqs h2{
    text-align: center;
    margin-bottom: 5rem;
}

section#faqs .content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

section#faqs .content article{
    padding: 2rem;
    border-radius: 0.5rem;
    display: flex;
    gap: 1.4rem;
    height: fit-content;
    background: var(--color-primary);
    cursor: pointer;
}

section#faqs .content article h4{
    color: var(--color-white);
    font-size: 1rem;
}

section#faqs .content article i {
    color: var(--color-white);
    font-size: 1.2rem;
}

section#faqs .content article p{
    margin-top: 1rem;
    color: var(--color-light);
    display: none;
}

section#faqs .content article.open p{
    display: block;
}

section#faqs a.btn{
    display: block;
    margin: 0 auto;
    width: 12rem;
    text-align: center;
    margin-top: 1.6rem;
}


/* =============================== TABLET ====================================== */

@media screen and (max-width: 1024px){
    .nav__navbar h1{
        font-size: 4.4rem;
        letter-spacing: 1px;
    }
    .nav__navbar h2{
        font-size: 2.7rem;
        letter-spacing: -1px;
    }
    .nav__navbar h3{
        font-size: 2.2rem;
    }
    .nav__navbar h4{
        font-size: 1.2rem;
    }
    .nav__navbar h5{
        font-size: 0.9rem;
    }
    .nav__navbar p{
        line-height: 1.6rem;
        font-size: 0.9rem;
    }
    .nav__navbar .container__navbar{
        width: var(--mobile-width);
    }
    .nav__navbar section{
        overflow-x: hidden;
    }
    /* =============================== NAVBAR ====================================== */
    .nav__navbar .container__navbar{
        width: 100%;
    }
    .nav__navbar .container__navbar .logo{
        margin-left: 1rem;
    }
    .nav__navbar .container__navbar ul{
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: column;
        width: 16rem;
        height: 100vh;
        background: var(--color-primary);
        box-shadow: 1rem 0 2rem rgba(0, 0, 0, 0.2);
        z-index: 3;
        display: none;
    }
    .nav__navbar .container__navbar ul li{
        height: 4rem;
        display: flex;
        align-items: center;
    }
    .nav__navbar .container__navbar ul li a{
        width: 100%;
        padding: 0 0 1rem 2rem;
    }
    .nav__navbar .container__navbar ul li a:hover{
        padding-left: 3rem;
    }
    .nav__navbar button{
        display: inline-block;
        cursor: pointer;
        background: transparent;
        color: var(--color-black);
        margin-right: 2rem;
        font-size: 1.6rem;
    }
    .nav__navbar button#close-btn{
        display: none;
    }
    /* =============================== HEADER ====================================== */
    header{
        top: 7rem;
        height: 100%;
    }
    header .container__navbar{
        grid-template-columns: 1fr 1fr;
        gap: 0;
        margin-top: 0;
    }
    header .container__navbar .details{
        display: none;
    }
    /* =============================== PRODUCTS ====================================== */
    .nav__navbar section#products .container__navbar{
        width: var(--mobile-width);
    }
    /* =============================== FAQS ====================================== */
    section#faqs h2{
        margin-bottom: 3rem;
    }
    section#faqs .content{
        grid-template-columns: 1fr;
    }
    section#faqs .content article{
        padding: 1.5rem;
    }
    /* =============================== CTA ====================================== */
    section#cta .container__navbar{
        padding: 4rem 2rem;
    }
    section#cta .info{
        width: 56%;
        align-self: flex-start;
    }
    section#cta .container__navbar .image{
        position: absolute;
        right: 11rem;
    }
    section#cta .container__navbar .btn{
        z-index: 1;
    }
}

/* =============================== PHONE ====================================== */

@media screen and (max-width: 600px){
    .nav__navbar h1{
        font-size: 3.4rem;
    }
    .nav__navbar h2{
        font-size: 2.4rem;
    }
    .nav__navbar h3{
        font-size: 1.6rem;
    }
    .nav__navbar h4{
        font-size: 0.96rem;
    }
    .nav__navbar h5{
        font-size: 0.7rem;
    }
    .nav__navbar p{
        font-size: 0.84rem;
    }
    .nav__navbar section{
        padding-top: 8rem;
    }
    /* =============================== NAVBAR ====================================== */
    .nav__navbar .container__navbar .icons{
        gap: 1rem;
    }
    /* =============================== HEADER ====================================== */
    header .container__navbar{
        grid-template-columns: 1fr;
        text-align: center;
    }
    header.delivery{
        display: none;
    }
    header .container__navbar .info p{
        font-size: 1rem;
    }
    header .container__navbar .cta{
        flex-direction: column;
        margin-top: 1.4rem;
        gap: 1rem;
    }
    header .container__navbar .header-image{
        grid-row: 1;
        width: 60vw;
        margin: 0 auto;
    }
    /* =============================== COMMERCIAL ====================================== */
    section#commercial .container__navbar{
        width: var(--mobile-width);
        margin: 0 auto;
    }
    /* =============================== PRODUCTS ====================================== */
    section#products{
        margin-top: 4rem;
    }
    section#products .container__navbar article{
        grid-template-columns: 1fr;
        text-align: center;
        gap: 1.4rem;
    }
    section#products .container__navbar article p{
        margin: 1rem 0;
    }
    section#products .container__navbar article .image{
        width: 100%;
        padding-left: 5rem;
    }
    /* =============================== CTA ====================================== */
    section#cta .container__navbar{
        flex-direction: column;
        height: fit-content;
        padding: 2rem;
    }
    section#cta .info{
        width: 100%;
    }
    section#cta .container .info h3{
        font-size: 2.3rem;
    }
    section#cta .container .info p{
        font-size: 1rem;
    }
    section#cta .container .image{
        position: relative;
        top: 2rem;
        right: 30%;
    }
}