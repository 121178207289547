.roadmap-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    position: relative;
}

.title{
    padding-top: 10;
    padding-bottom: 0;
    text-decoration: underline;
}

/* Vertical line in the center of the container */
.roadmap-container::before {
    content: '';
    position: absolute;
    left: 5%;
    width: 2px;
    background-color: white;
    top: 50px;
    bottom: 0;
}

.roadmap-container > div {
    width: 100%; /* Ensure each div fills the container */
}

/* Adjust item positioning */
.roadmap-container > div:nth-child(odd) .timeline-item-container {
    text-align: left;
    padding-left: 5%; /* Shift right to the center line */
    padding-right: 0px; /* Spacing for text */
}

.roadmap-container > div:nth-child(even) .timeline-item-container {
    text-align: right;
    padding-right: 50%; /* Shift left to the center line */
    padding-left: 20px; /* Spacing for text */
}
