#contained-streetbites-updates body{
    margin:0px;
    padding: 0px;
    
    background-color: #ffffff;
    color: white;
}
*{
    box-sizing: border-box;
}

.streetbites-updates{
    margin: 100px;
    padding: 50px;
    background-color: beige;
    outline: white;
    border: 10px solid orange;
    border-radius: 50px;
}

.streetbites-updates h1,h2{
    color: black;
}

.streetbites-updates p{
    color: black;
    margin: 20px;
}

@media screen and (max-width : 600px) {
    .streetbites-updates{
        margin: 10px;
        padding: 20px;
    }
    .streetbites-updates h1{
        color: black;
        font-size: 30px;
    }
    .streetbites-updates h2{
        color: black;
        font-size: 10px;
    }
}

