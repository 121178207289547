.portfolio__container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1rem;
}

.portfolio__container_horizental{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 1rem;
    width: auto;
}

.portfolio__item{
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 2rem;
    border: 3px solid transparent;
    display:-moz-groupbox;
    transition: var(--transition);
}

.portfolio_description_verticle{
    display: grid;
    grid-template-columns: repeat(1,1fr);
}

.portfolio__item_horizental{
    background: var(--color-bg-variant);
    padding: 0.25rem;
    border-radius: 2rem;
    border: 3px solid transparent;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    transition: var(--transition);
    width: auto;
}

.portfolio__item_horizental h4{
    width: 50vw;
    font-family: Arial, Helvetica, sans-serif;
    font-style: bold;
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item_horizental:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image{
    border-radius: 1.5rem;
    max-width: 25rem;
    max-height:13rem;
    overflow: hidden;
}

.portfolio__item_horizental-image{
    border-radius: 1.5rem;
    max-width: 15rem;
    max-height:15rem;
    overflow: hidden;
    object-fit: contain;
    margin: 1rem;
}

.portfolio__item h3{
    margin: 0.5rem 0 0.5rem;
}

.portfolio__item-cta{
    display: grid;
    grid-template-columns: repeat(6,0fr);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.btn-skills{
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: black;
    outline: 1px solid #919194;
}

/* ========================= MEDIA QUERIES (MEDIUM DEVICES ================== */
@media screen and (max-width : 1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ========================= MEDIA QUERIES (SMALL DEVICES ================== */
@media screen and (max-width : 600px) {
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .portfolio__container_horizental{
        padding: 1rem;
        gap: 1rem;
    }
    .portfolio__item_horizental{
        background: var(--color-bg-variant);
        padding: 10px;
        border-radius: 2rem;
        border: 3px solid transparent;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        transition: var(--transition);
        width: auto;
    }
    .portfolio__item-cta{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .portfolio__item_horizental h4{
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
        font-style: bold;
    }
    
}