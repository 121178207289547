.gallery{
    /* -webkit-column-count: 4;
    -moz-column-count: 4; */
    column-count: 4;
    /* -webkit-column-width: 25%;
    -moz-column-count: 25%;
    column-width: 25%; */
    padding: 0 12px;
}

.homebtn{
    padding: 1rem;
    text-align: center;
    
    display: flex;
    align-items: center;
}

.title{
    padding: 1rem 0rem 4rem 0rem;
    text-align: center;
}

.links{
    display: flex;
    justify-content: space-between;
}

.links h3{
    padding: 0 2rem;
}

.gallery .pics{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.gallery img{
    border-radius: 8px;
    border: 3px solid rgba(233, 135, 31, 0.4);
}

.gallery .pics:hover{
    filter: opacity(0.8);
}

@media (max-width : 991px){
    .gallery{
        -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    }
}

@media (max-width : 480px){
    /* .gallery{
        -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-count: 100%;
    column-width: 100%;
    } */
}

.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition: opacity .4s ease, visibility .4s ease , transform .5s ease-in-out ;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
}