.timeline-item-container {
    list-style: none;
    margin: 20px 0;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
}

.timeline-item-container::before {
    content: '';
    position: absolute;
    left: 0; /* Adjust this to center the dot on the line */
    transform: translateX(-50%); /* Center the dot horizontally */
    top: 20px;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    z-index: 2;
}

.timeline-item-container li {
    background-color: beige; /* Beige background */
    color: black;
    padding: 5px 10px;       /* Padding for some spacing inside the badge */
    border-radius: 8px;      /* Rounded corners */
    border-width: 2px;
    border: 2px solid orange;
}

.timeline-item-container span, /* Year */
.timeline-item-container h3, /* Title */
.timeline-item-container div:first-of-type, /* Duration */
.timeline-item-container p:last-child /* Details */ {
    margin-bottom: 5px;
    padding: 5px;
}

.timeline-item-container p:last-child {
    color: black;
}

.timeline-item-header{
    display: flex;     /* Aligns children in a row */
    gap: 20px;         /* Adds space between the items */
    align-items: center; /* Centers items vertically */
    color: black;
}

.year-badge{
    background-color:rgb(207, 205, 233); /* Beige background */
    color: black;
    padding: 5px 10px;       /* Padding for some spacing inside the badge */
    border-radius: 8px;      /* Rounded corners */
}

.duration-badge{
    text-decoration: underline;
    color: black;
}
